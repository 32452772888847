import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import {
  dropdownData,
  fetchData,
  image_url,
  academics,
  user,
  downloadBlobData,
} from "../../../services/fetchData";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Asterisk from "../../Asterisk/Asterisk";
import { dropStyle } from "../../../Style/dropStyle";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const ContentChannelCreate = (props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const navigate = useNavigate();
  let { id } = useParams();

  //toggling modals
  const [tagsIsHide, setTagsIsHide] = useState(false);
  const [postIsHide, setPostIsHide] = useState(true);
  const [categoriesIsHide, setCategoriesIsHide] = useState(true);
  const [moduleIsHide, setModuleIsHide] = useState(true);
  const [keywordIsHide, setKeywordIsHide] = useState(true);

  //selected Group Drop
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedGroupOption, setSelectedGroupOption] = useState([]);
  const [previousSelectedGroup, setPreviousSelectedGroup] = useState([]);

  //selected Audiance drop
  const [selectedAudiance, setSelectedAudiance] = useState([]);
  const [selectedAudianceOption, setSelectedAudianceOption] = useState([]);
  const [previousSelectedAudiance, setPreviousSelectedAudiance] = useState([]);

  //  Enable mode
  const [selectedEnableMode, setSelectedEnableMode] = useState(null);

  // TagList
  const [tagSelection, setTagSelection] = useState([]);
  const [tagFilteredSelection, setTagFilteredSelection] = useState([]);
  const [tagSelected, setTagSelected] = useState([]);
  const [tagFilteredSelected, setTagFilteredSelected] = useState([]);
  const [tagSelectedItem, setTagSelectedItem] = useState([]);
  const [tagRemoveSelectedItem, setRemoveTagSelectedItem] = useState([]);
  const [tagSelectedSearch, setTagSelectedSearch] = useState("");
  const [tagSelectionSearch, setTagSelectionSearch] = useState("");

  //PostList
  const [postSelection, setPostSelection] = useState([]);
  const [postFilteredSelection, setPostFilteredSelection] = useState([]);
  const [postSelected, setPostSelected] = useState([]);
  const [postFilteredSelected, setPostFilteredSelected] = useState([]);
  const [postSelectedItem, setPostSelectedItem] = useState([]);
  const [postRemoveSelectedItem, setRemovePostSelectedItem] = useState([]);
  const [postSelectionSearch, setPostSelectionSearch] = useState("");
  const [postSelectedSearch, setPostSelectedSearch] = useState("");

  //ModuleList
  const [moduleSelection, setModuleSelection] = useState([]);
  const [moduleFilteredSelection, setModuleFilteredSelection] = useState([]);
  const [moduleSelected, setModuleSelected] = useState([]);
  const [moduleFilteredSelected, setModuleFilteredSelected] = useState([]);
  const [moduleSelectedItem, setModuleSelectedItem] = useState([]);
  const [moduleRemoveSelectedItem, setRemoveModuleSelectedItem] = useState([]);
  const [moduleSelectionSearch, setModuleSelectionSearch] = useState("");
  const [moduleSelectedSearch, setModuleSelectedSearch] = useState("");

  //CategoryList
  const [categorySelection, setCategorySelection] = useState([]);
  const [categoryFilteredSelection, setCategoryFilteredSelection] = useState(
    []
  );
  const [categoryFilteredSelected, setCategoryFilteredSelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [categorySelectedItem, setCategorySelectedItem] = useState([]);
  const [categoryRemoveSelectedItem, setRemoveCategorySelectedItem] = useState(
    []
  );
  const [categorySelectionSearch, setCategorySelectionSearch] = useState("");
  const [categorySelectedSearch, setCategorySelectedSearch] = useState("");

  // KeywordList
  const [keywordSelection, setKeywordSelection] = useState([]);
  const [keywordFilteredSelection, setKeywordFilteredSelection] = useState([]);
  const [keywordSelected, setKeywordSelected] = useState([]);
  const [keywordFilteredSelected, setKeywordFilteredSelected] = useState([]);
  const [KeywordSelectedItem, setKeywordSelectedItem] = useState([]);
  const [KeywordRemoveSelectedItem, setKeywordRemoveSelectedItem] = useState(
    []
  );
  const [keywordSelectionSearch, setkeywordSelectionSearch] = useState("");
  const [keywordSelectedSearch, setkeywordSelectedSearch] = useState("");

  //media preview
  const [src, setSrc] = useState("");
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] =
    useState(false);

  //loading
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(false);

  const [fetchedData, setFetchedData] = useState(null);

  const [postData, setPostData] = useState([]);
  const [postAsso, setPostAsso] = useState([]);

  const [moduleData, setModuleData] = useState([]);
  const [moduleAsso, setModuleAsso] = useState([]);

  // tenat drop
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantlist, setTenantlist] = useState([]);
  const [tenantError, setTenantError] = useState(false);

  // storing formData data
  const [formData, setFormData] = useState({
    inputData: {
      type: 2,
      tenant_id: "",
      channel_image: "",
      channel_title: "",
      summary: "",
      channel_keyword: [],
      setting: 1,
      subscription: "",
      speaker_name: "",
      enable_notification: 0,
      post_title: "",
      post_subtitle: "",
      keyword: "",
      speaker_stock_id: "",
      released_date: null,
      media: {
        title: "",
        type: "",
        url: "",
        content: "",
      },
      academic_channel_group_linkings: [],
      academic_channel_audience_linkings: [],
      academic_channel_tags: [],
      academic_channel_categories: [],
      academic_channel_users: [],
      academic_channel_post_associations: [],
      shareable: 0,
      is_having_module: 0,
      is_active: 1,
    },

    uploadImage: {
      imagePath: "",
      folderPath: "uploads",
      field_name: "channel_image",
    },
  });

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    getTagListData();
    getPostListData();
    getModuleListData();
    getCategoryListData();
    getKeywordListData();
  }, []);

  useEffect(() => {
    getTenantDropdownData();
  }, []);

  useEffect(() => {
    setTagFilteredSelection(tagSelection);
    setModuleFilteredSelection(moduleSelection);
    setPostFilteredSelection(postSelection);
    setCategoryFilteredSelection(categorySelection);
    setKeywordFilteredSelection(keywordSelection);
  }, [
    tagSelection,
    moduleSelection,
    postSelection,
    categorySelection,
    keywordSelection,
  ]);

  useEffect(() => {
    setTagFilteredSelected(tagSelected);
    setModuleFilteredSelected(moduleSelected);
    setPostFilteredSelected(postSelected);
    setCategoryFilteredSelected(categorySelected);
    setKeywordFilteredSelected(keywordSelected);
  }, [
    tagSelected,
    moduleSelected,
    postSelected,
    categorySelected,
    keywordSelected,
  ]);

  useEffect(() => {
    if (fetchedData) {
      const defaultKeywords = [
        { type: "header", isChecked: false },
        { type: "title", isChecked: false },
        { type: "follows", isChecked: false },
      ];

      let mappedData = {
        ...fetchedData,
        channel_keyword:
          Array.isArray(fetchedData.channel_keyword) &&
          fetchedData.channel_keyword.length > 0
            ? fetchedData.channel_keyword
            : defaultKeywords,
      };

      let data = mappedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.tenant_id) {
        getTenantDropdownData(data.tenant_id);
      }

      if (data.channel_image) {
        setSrc(image_url + `${data.channel_image}`);
      }

      const initialGroups = data.academic_channel_group_linkings &&
      data.academic_channel_group_linkings.map(
        (group) => {
          return {
            id: group.id,
            channel_id: group.channel_id,
            value: group.group_id,
            label: group.temp_group_id_masters?.llc_group,
            is_active: 1,
          };
        }
      );
      setSelectedGroup(initialGroups);
      setPreviousSelectedGroup(initialGroups);

  
      const initialAudiences = data.academic_channel_audience_linkings &&
      data.academic_channel_audience_linkings.map(
        (audience) => {
          return {
            id: audience.id,
            channel_id: audience.channel_id,
            value: audience.audience_id,
            label: audience.temp_audience_id_masters?.llc,
            is_active: 1,
          };
        }
      );
      setSelectedAudiance(initialAudiences);
      setPreviousSelectedAudiance(initialAudiences);

      let accessTagId = data.academic_channel_tags.map((item) => item.tag_id);
      let newfilterTagData = tagSelection.filter((item) =>
        accessTagId.includes(item.id)
      );

      setTagSelected(
        newfilterTagData.map((tag) => ({
          id: tag.id,
          tag_name: tag.tag_name,
          is_active: tag.is_active,
        }))
      );

      setPostAsso(data.academic_channel_post_associations);
      let accessPostId = postSelection.map((item) => item.id);
      let newfilterPostData = data.academic_channel_post_associations.filter(
        (item) => accessPostId.includes(item.post_id)
      );
      setPostSelected(
        newfilterPostData.map((post) => ({
          title: post.academic_channel_posts.title,
          id: post.post_id,
          sequence: post.sequence,
          is_active: post.is_active,
        }))
      );
      setPostData(
        newfilterPostData.map((post) => ({
          title: post.academic_channel_posts.title,
          id: post.post_id,
          sequence: post.sequence,
          is_active: post.is_active,
        }))
      );

      setModuleAsso(data.academic_channel_post_associations);
      let accessModuleId = data.academic_channel_post_associations.map(
        (item) => item.module_id
      );
      let newfilterModuleData = moduleSelection.filter((item) =>
        accessModuleId.includes(item.id)
      );
      setModuleSelected(
        newfilterModuleData.map((module) => ({
          title: module.title,
          id: module.id,
          is_active: module.is_active,
        }))
      );
      setModuleData(
        newfilterModuleData.map((module) => ({
          title: module.title,
          id: module.id,
          is_active: module.is_active,
        }))
      );

      let accessCategoryId = data.academic_channel_categories.map(
        (item) => item.category_id
      );
      let newfilterCategoryData = categorySelection.filter((item) =>
        accessCategoryId.includes(item.id)
      );
      setCategorySelected(
        newfilterCategoryData.map((category) => ({
          category_name: category.category_name,
          id: category.id,
          is_active: category.is_active,
        }))
      );

      if (
        data.channel_keyword &&
        Array.isArray(data.channel_keyword) &&
        data.channel_keyword.length > 0
      ) {
        let accessKeywordId =
          data.channel_keyword &&
          Array.isArray(data.channel_keyword) &&
          data.channel_keyword.length > 0
            ? data.channel_keyword.map((item) => item.keyword_id)
            : [];
        let newfilterKeywordData = keywordSelection.filter((item) => {
          return accessKeywordId.includes(item.id);
        });

        setKeywordSelected(
          newfilterKeywordData.map((keyword) => ({
            id: keyword.id,
            keyword_name: keyword.keyword_name,
          }))
        );
      }
    }
  }, [
    fetchedData,tagSelection,moduleSelection,postSelection,categorySelection,keywordSelection
  ]);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "academic_channels",
        relations: [
          {
            module: "academic_channel_group_linkings",
            moduleas: "academic_channel_group_linkings",
            subModule: "temp_group_id_masters",
            subModuleas: "temp_group_id_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_audience_linkings",
            moduleas: "academic_channel_audience_linkings",
            subModule: "temp_audience_id_masters",
            subModuleas: "temp_audience_id_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_tags",
            moduleas: "academic_channel_tags",
            subModule: "tag_masters",
            subModuleas: "tag_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_categories",
            moduleas: "academic_channel_categories",
            subModule: "temp_category_masters",
            subModuleas: "temp_category_masters",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_users",
            moduleas: "academic_channel_users",
            subModule: "temp_user_datas",
            subModuleas: "temp_user_datas",
            isSubWhereConditionRequired: true,
          },
          {
            module: "academic_channel_post_associations",
            moduleas: "academic_channel_post_associations",
            subModule: "academic_channel_posts",
            subModuleas: "academic_channel_posts",
            isSubWhereConditionRequired: true,
          },
        ],

        subWhereCondition: {
          is_active: 1,
        },

        whereCondition: {
          is_active: 1,
        },
      };

      if (id) {
        payload.whereCondition = { id: id };
      }
      let responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];

        if (id) {
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  // get ( tag/post/category/user ) list data
  const getTagListData = async () => {
    try {
      const payload = {
        modelName: "tag_masters",
        whereCondition: { is_active: 1 },
      };
      const responseData = await fetchData("getMasterList", payload, academics);
      if (responseData && responseData.code) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);

        setTagSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPostListData = async () => {
    try {
      let payload = {
        modelName: "academic_channel_posts",
        whereCondition: {
          type: 2,
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setPostSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getModuleListData = async () => {
    try {
      let payload = {
        modelName: "academic_channel_modules",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        responseData.data.forEach((element, index) => {
          element.index = index + 1;
        });
        responseData.data = responseData.data.sort((a, b) => b.index - a.index);
        setModuleSelection(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCategoryListData = async () => {
    try {
      let authID = localStorage.getItem("logedin-user");
      authID = JSON.parse(authID);
      const payload = {
        modelName: "category_masters",
        relations: [{ module: "tenant_masters", moduleas: "tenant_masters" }],
        whereCondition: { type: 2, is_deleted: 0 },
      };
      const responseData = await fetchData("getMasterList", payload);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);

      setCategorySelection(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getKeywordListData = async () => {
    try {
      const payload = {
        modelName: "default_keywords",
        whereCondition: {
          is_active: 1,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });

      const sortedData = responseData.data.sort((a, b) => b.index - a.index);
      setKeywordSelection(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get Select Group drop data
  const getSelectGroupDropData = async (tenantId) => {
    try {
      let payload = {
        modelName: "group_id_masters",
        whereCondition: {
          is_active: 1,
          is_deleted: 0,
          tenant_id: tenantId,
        },
      };
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_group_id"
        );
        const flattenedOptions = dropdownResponseData.reduce((acc, item) => {
          return acc.concat(item.options);
        }, []);
        setSelectedGroupOption([
          { value: "select-all", label: "Select All" },
          ...flattenedOptions,
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get Select Audiance drop data
  const getSelectAudianceDropData = async (tenantId) => {
    try {
      let payload = {
        modelName: "temp_audience_id_masters",
        whereCondition: {
          is_active: 1,
          tenant_id: tenantId,
        },
      };

      const responseData = await fetchData("getMasterList", payload, academics);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_audience_id"
        );
         const flattenedOptions = dropdownResponseData.reduce((acc, item) => {
          return acc.concat(item.options);
        }, []);
        setSelectedAudianceOption([
          { value: "select-all", label: "Select All" },
          ...flattenedOptions,
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get Tenant drop data
  const getTenantDropdownData = async (id) => {
    try {
      let tenantId = localStorage.getItem("tenantId"); // type 1=admin,2=tenant
      let authID = localStorage.getItem("logedin-user"); // type 1=admin,2=tenant
      authID = JSON.parse(authID);
      authID = authID[0];

      let payload = {
        modelName: "tenant_masters",
        whereCondition: {
          is_active: 1,
        },
      };

      if (authID.type == 1 && tenantId) {
        // admin
        payload.whereCondition.id = tenantId;
      }
      if (authID.type == 2) {
        // tenant id
        payload.whereCondition.id = authID.tenant_id;
      }

      const responseData = await fetchData("getMasterList", payload);
      const dropdownResponseData = await dropdownData(
        responseData.data,
        "tenant_company_name"
      );
      if (id) {
        dropdownResponseData[0].options.forEach((element) => {
          if (element.value == id) {
            setSelectedTenant(element);
            getSelectGroupDropData(element.value);
            getSelectAudianceDropData(element.value);
          }
        });
      }
      setTenantlist(dropdownResponseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //  onchange Tenant / Group / Audiance

  const handleSelectGroupChange = (selectedGroupOptionValues) => {
    let currentGroupIds = selectedGroup.map((group) => group.value);
    let allGroupOptions = selectedGroupOptionValues
      .filter((option) => option.value !== "select-all")
      .map((option) => option.value);

    if (
      selectedGroupOptionValues &&
      selectedGroupOptionValues.some((option) => option.value === "select-all")
    ) {
      const selectedGroupFilterdOption = selectedGroupOption.filter(
        (option) => option.value !== "select-all"
      );
      const removedGroups = previousSelectedGroup
        .filter((group) => !allGroupOptions.includes(group.value))
        .map((group) => ({ ...group, is_active: 0 }));
      const addedGroups = selectedGroupFilterdOption
        .filter((group) => !currentGroupIds.includes(group.value))
        .map((group) => ({
          value: group.value,
          label: group.label,
          is_active: 1,
        }));

      const updatedGroups = [
        ...previousSelectedGroup.filter(
          (group) =>
            allGroupOptions.includes(group.value) && group.is_active == 1
        ),
        ...addedGroups,
        ...removedGroups,
      ];
      setSelectedGroup(
        selectedGroupOption.filter((option) => option.value !== "select-all")
      );
      setPreviousSelectedGroup(updatedGroups);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_group_linkings: updatedGroups.map((group) => ({
            id: group.id,
            channel_id: group.channel_id,
            group_id: group.value,
            is_active: group.is_active,
          })),
        },
      }));
    } else {
      const removedGroups = previousSelectedGroup
        .filter((group) => !allGroupOptions.includes(group.value))
        .map((group) => ({ ...group, is_active: 0 }));
      const addedGroups = selectedGroupOptionValues
        .filter((group) => !currentGroupIds.includes(group.value))
        .map((group) => ({
          value: group.value,
          label: group.label,
          is_active: 1,
        }));

      const updatedGroups = [
        ...previousSelectedGroup.filter(
          (group) =>
            allGroupOptions.includes(group.value) && group.is_active == 1
        ),
        ...addedGroups,
        ...removedGroups,
      ];
      setSelectedGroup(selectedGroupOptionValues);
      setPreviousSelectedGroup(updatedGroups);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_group_linkings: updatedGroups.map((group) => ({
            id: group.id,
            channel_id: group.channel_id,
            group_id: group.value,
            is_active: group.is_active,
          })),
        },
      }));
    }
  };

  const handleSelectAudienceChange = (selectedAudianceOptionValues) => {
    const currentAudienceIds = selectedAudiance.map(
      (audience) => audience.value
    );
    let allAudianceOptions = selectedAudianceOptionValues
      .filter((option) => option.value !== "select-all")
      .map((option) => option.value);

    if (selectedAudianceOptionValues &&selectedAudianceOptionValues.some((option) => option.value === "select-all")
    ) {
      const selectedAudianceFilterdOption = selectedAudianceOption.filter(
        (option) => option.value !== "select-all"
      );
      const removedAudiences = previousSelectedAudiance
        .filter((audience) => !allAudianceOptions.includes(audience.value))
        .map((audience) => ({ ...audience, is_active: 0 }));
      const addedAudiences = selectedAudianceFilterdOption
        .filter((audience) => !currentAudienceIds.includes(audience.value))
        .map((audience) => ({
          value: audience.value,
          label: audience.label,
          is_active: 1,
        }));

      const updatedAudiences = [
        ...previousSelectedAudiance.filter(
          (audience) =>
            allAudianceOptions.includes(audience.value) &&
            audience.is_active == 1
        ),
        ...addedAudiences,
        ...removedAudiences,
      ];

      setSelectedAudiance(
        selectedAudianceOption.filter((option) => option.value !== "select-all")
      );
      setPreviousSelectedAudiance(updatedAudiences);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_audience_linkings: updatedAudiences.map(
            (audience) => ({
              id: audience.id,
              channel_id: audience.channel_id,
              audience_id: audience.value,
              is_active: audience.is_active,
            })
          ),
        },
      }));
    } else {
      const removedAudiences = previousSelectedAudiance
        .filter((audience) => !allAudianceOptions.includes(audience.value))
        .map((audience) => ({ ...audience, is_active: 0 }));
      const addedAudiences = selectedAudianceOptionValues
        .filter((audience) => !currentAudienceIds.includes(audience.value))
        .map((audience) => ({
          value: audience.value,
          label: audience.label,
          is_active: 1,
        }));

      const updatedAudiences = [
        ...previousSelectedAudiance.filter(
          (audience) =>
            allAudianceOptions.includes(audience.value) &&
            audience.is_active == 1
        ),
        ...addedAudiences,
        ...removedAudiences,
      ];
      setSelectedAudiance(selectedAudianceOptionValues);
      setPreviousSelectedAudiance(updatedAudiences);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_audience_linkings: updatedAudiences.map(
            (audience) => ({
              id: audience.id,
              channel_id: audience.channel_id,
              audience_id: audience.value,
              is_active: audience.is_active,
            })
          ),
        },
      }));
    }
  };

  const handleSelectTenantChange = (selectedOption) => {
    setSelectedGroup([]);
    setSelectedGroupOption([]);
    setSelectedAudiance([]);
    setSelectedAudianceOption([]);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_group_linkings:
          prevState.inputData.academic_channel_group_linkings.map((group) => ({
            ...group,
            is_active: 0,
          })),
        academic_channel_audience_linkings:
          prevState.inputData.academic_channel_audience_linkings.map(
            (audience) => ({
              ...audience,
              is_active: 0,
            })
          ),
      },
    }));

    if (selectedOption) {
      getSelectGroupDropData(selectedOption?.value);
      getSelectAudianceDropData(selectedOption?.value);
    } else {
      setSelectedGroup([]);
      setSelectedGroupOption([]);
      setSelectedAudiance([]);
      setSelectedAudianceOption([]);
    }
    setSelectedTenant(selectedOption);
    setTenantError(!selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        tenant_id: selectedOption ? selectedOption.value : "",
      },
    }));
  };
  
  // For changing tooltip display prop
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  // Tags/Post/Category/Users modal open/close (toggeling)
  const handelTags = () => {
    setTagsIsHide(!tagsIsHide);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    setModuleIsHide(true);
    setKeywordIsHide(true);

    if (!tagsIsHide) {
      scrollToSection("tagSection");
    }
  };

  const handelPost = () => {
    setPostIsHide(!postIsHide);
    setTagsIsHide(true);
    setCategoriesIsHide(true);
    setModuleIsHide(true);
    setKeywordIsHide(true);
    if (postIsHide) {
      scrollToSection("postSection");
    }
  };

  const handelCategories = () => {
    setCategoriesIsHide(!categoriesIsHide);
    setTagsIsHide(true);
    setPostIsHide(true);
    setModuleIsHide(true);
    setKeywordIsHide(true);
    if (categoriesIsHide) {
      scrollToSection("categoriesSection");
    }
  };

  const handelModule = () => {
    setModuleIsHide(!moduleIsHide);
    setTagsIsHide(true);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    setKeywordIsHide(true);

    if (moduleIsHide) {
      scrollToSection("moduleSection");
    }
  };

  const handelKeyword = () => {
    setKeywordIsHide(!keywordIsHide);
    setTagsIsHide(true);
    setPostIsHide(true);
    setCategoriesIsHide(true);
    setModuleIsHide(true);

    if (keywordIsHide) {
      scrollToSection("keywordSection");
    }
  };

  // Tags/Module/Post/Category/Users - handleSearch
  const handleTagSelectionSearch = (e) => {
    const { value } = e.target;
    setTagSelectionSearch(value);
    if (value === "") {
      setTagFilteredSelection(tagSelection);
    } else {
      setTagFilteredSelection(
        tagSelection.filter((item) =>
          item.tag_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleTagSelectedSearch = (e) => {
    const { value } = e.target;
    setTagSelectedSearch(value);
    if (value === "") {
      setTagFilteredSelected(tagSelected);
    } else {
      setTagFilteredSelected(
        tagSelected.filter((item) =>
          item.tag_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleModuleSelectionSearch = (e) => {
    const { value } = e.target;
    setModuleSelectionSearch(value);
    if (value === "") {
      setModuleFilteredSelection(moduleSelection);
    } else {
      setModuleFilteredSelection(
        moduleSelection.filter((item) =>
          item.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleModuleSelectedSearch = (e) => {
    const { value } = e.target;
    setModuleSelectedSearch(value);
    if (value === "") {
      setModuleFilteredSelected(moduleSelected);
    } else {
      setModuleFilteredSelected(
        moduleSelected.filter((item) =>
          item.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handlePostSelectiontSearch = (e) => {
    const { value } = e.target;
    setPostSelectionSearch(value);
    if (value === "") {
      setPostFilteredSelection(postSelection);
    } else {
      setPostFilteredSelection(
        postSelection.filter((item) =>
          item.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handlePostSeletedSearch = (e) => {
    const { value } = e.target;
    setPostSelectedSearch(value);
    if (value === "") {
      setPostFilteredSelected(postSelected);
    } else {
      setPostFilteredSelected(
        postSelected.filter((item) =>
          item.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleCategorySelectionSearch = (e) => {
    const { value } = e.target;
    setCategorySelectionSearch(value);
    if (value === "") {
      setCategoryFilteredSelection(categorySelection);
    } else {
      setCategoryFilteredSelection(
        categorySelection.filter((item) =>
          item.category_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleCategorySelectedSearch = (e) => {
    const { value } = e.target;
    setCategorySelectedSearch(value);
    if (value === "") {
      setCategoryFilteredSelected(categorySelected);
    } else {
      setCategoryFilteredSelected(
        categorySelected.filter((item) =>
          item.category_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleKeywordSelectionSearch = (e) => {
    const { value } = e.target;
    setkeywordSelectionSearch(value);
    if (value === "") {
      setKeywordFilteredSelection(keywordSelection);
    } else {
      setKeywordFilteredSelection(
        keywordSelection.filter((item) =>
          item.keyword_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  const handleKeywordSelectedSearch = (e) => {
    const { value } = e.target;
    setkeywordSelectedSearch(value);
    if (value === "") {
      setKeywordFilteredSelected(keywordSelected);
    } else {
      setKeywordFilteredSelected(
        keywordSelected.filter((item) =>
          item.keyword_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  // handel select  (Tags/Modal/Post/Category/User/keyword)

  const handleTagSelect = () => {
    const newSelectedTags = tagSelected.concat(tagSelectedItem);
    const newAcademicTags = tagSelectedItem.map((item) => ({
      ...(id ? { id: null } : {}),
      tag_id: item.id,
      is_active: 1,
    }));

    setTagSelected(newSelectedTags);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_tags: [
          ...prevState.inputData.academic_channel_tags,
          ...newAcademicTags,
        ],
      },
    }));
    setTagSelectedItem([]);
  };

  const handleModuleSelect = () => {
    const newSelectedModule = moduleSelected.concat(moduleSelectedItem);

    // Initialize arrays for matched and unmatched records
    const matchedRecords = [];
    const unmatchedModules = [];

    // Loop through the selected modules and handle matching logic
    moduleSelectedItem.forEach((module) => {
      const matchedModule = moduleData.find((m) => m.id === module.id);

      if (matchedModule) {
        // Find associated records for matched modules
        const associatedRecord = moduleAsso.find(
          (asso) => asso.module_id === matchedModule.id
        );
        if (associatedRecord) {
          matchedRecords.push(associatedRecord);
        } else {
          // If no associated record, treat it as unmatched
          unmatchedModules.push({
            module_id: module.id,
            post_id: null,
            is_active: 1,
          });
        }
      } else {
        // If no matched module, treat it as unmatched
        unmatchedModules.push({
          module_id: module.id,
          post_id: null,
          is_active: 1,
        });
      }
    });

    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_post_associations: [
          ...prevState.inputData.academic_channel_post_associations,
          ...matchedRecords,
          ...unmatchedModules,
        ],
      },
    }));

    setModuleSelected(newSelectedModule);
    setModuleSelectedItem([]);
  };

  const handlePostSelect = () => {
    const newSelectedPost = postSelected.concat(postSelectedItem);

    const matchedRecords = [];
    const unmatchedPosts = [];

    postSelectedItem.forEach((post) => {
      const matchedPost = postData.find((m) => m.id === post.id);

      if (matchedPost) {
        // Find associated records for matched modules
        const associatedRecord = postAsso.find(
          (asso) => asso.post_id === matchedPost.id
        );
        if (associatedRecord) {
          matchedRecords.push(associatedRecord);
        } else {
          // If no associated record, treat it as unmatched
          unmatchedPosts.push({
            post_id: post.id,
            module_id: null,
            is_active: 1,
          });
        }
      } else {
        // If no matched module, treat it as unmatched
        unmatchedPosts.push({
          post_id: post.id,
          module_id: null,
          is_active: 1,
        });
      }
    });

    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_post_associations: [
          ...prevState.inputData.academic_channel_post_associations,
          ...matchedRecords,
          ...unmatchedPosts,
        ],
      },
    }));

    setPostSelected(newSelectedPost);
    setPostSelectedItem([]);
  };

  const handleCategorySelect = () => {
    const newSelectedCategory = categorySelected.concat(categorySelectedItem);
    const newAcademicCategory = categorySelectedItem.map((item) => ({
      ...(id ? { id: null } : {}),
      category_id: item.id,
      is_active: 1,
    }));

    setCategorySelected(newSelectedCategory);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        academic_channel_categories: [
          ...prevState.inputData.academic_channel_categories,
          ...newAcademicCategory,
        ],
      },
    }));
    setCategorySelectedItem([]);
  };

  const handleKeywordSelect = () => {
    const newSelectedKeyword = keywordSelected.concat(KeywordSelectedItem);
    const newAcademicKeyword = KeywordSelectedItem.map((item) => ({
      keyword_id: item.id,
      keyword_name: item.keyword_name,
    }));

    setKeywordSelected(newSelectedKeyword);
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        channel_keyword: [
          ...prevState.inputData.channel_keyword,
          ...newAcademicKeyword,
        ],
      },
    }));
    setKeywordSelectedItem([]);
  };

  //handle Rememove item data   (Tags/Modal/Post/Category/User/keyword)

  const handleTagRemove = () => {
    if (tagRemoveSelectedItem.length > 0) {
      const removeTagIds = tagRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_tags: prevState.inputData.academic_channel_tags.map(
            (tag) => {
              if (removeTagIds.includes(tag.tag_id)) {
                return { ...tag, is_active: 0 };
              }
              return tag;
            }
          ),
        },
      }));

      // updatte the tagSelected state to reflect the remove on ui
      setTagSelected((prevSelected) =>
        prevSelected.filter((tag) => !removeTagIds.includes(tag.id))
      );

      setRemoveTagSelectedItem([]);
    } else {
      toast.error("No tags selected to remove");
    }
  };

  const handleModuleRemove = () => {
    if (moduleRemoveSelectedItem.length > 0) {
      const removeModuleIds = moduleRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_post_associations:
            prevState.inputData.academic_channel_post_associations.map(
              (module) => {
                if (removeModuleIds.includes(module.module_id)) {
                  return { ...module, is_active: 0 };
                }
                return module;
              }
            ),
        },
      }));
      setModuleSelected((prevSelected) =>
        prevSelected.filter((module) => !removeModuleIds.includes(module.id))
      );

      setRemoveModuleSelectedItem([]);
    } else {
      toast.error("No module selected to remove");
    }
  };

  const handlePostRemove = () => {
    if (postRemoveSelectedItem.length > 0) {
      const removePostIds = postRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_post_associations:
            prevState.inputData.academic_channel_post_associations.map(
              (post) => {
                if (removePostIds.includes(post.post_id)) {
                  return { ...post, is_active: 0 };
                }
                return post;
              }
            ),
        },
      }));

      setPostSelected((prevSelected) =>
        prevSelected.filter((post) => !removePostIds.includes(post.id))
      );
      setRemovePostSelectedItem([]);
    } else {
      toast.error("No post selected to remove");
    }
  };

  const handleCategoryRemove = () => {
    if (categoryRemoveSelectedItem.length > 0) {
      const removecategoryIds = categoryRemoveSelectedItem.map(
        (item) => item.id
      );

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          academic_channel_categories:
            prevState.inputData.academic_channel_categories.map((category) => {
              if (removecategoryIds.includes(category.category_id)) {
                return { ...category, is_active: 0 };
              }
              return category;
            }),
        },
      }));

      setCategorySelected((prevSelected) =>
        prevSelected.filter(
          (category) => !removecategoryIds.includes(category.id)
        )
      );
      setRemoveCategorySelectedItem([]);
    } else {
      toast.error("No category selected to remove");
    }
  };

  const handleKeywordRemove = () => {
    if (KeywordRemoveSelectedItem.length > 0) {
      const removeKeywordIds = KeywordRemoveSelectedItem.map((item) => item.id);

      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          channel_keyword: prevState.inputData.channel_keyword.filter(
            (keyword) => !removeKeywordIds.includes(keyword.keyword_id)
          ),
        },
      }));

      setKeywordSelected((prevSelected) =>
        prevSelected.filter((keyword) => !removeKeywordIds.includes(keyword.id))
      );
      setKeywordRemoveSelectedItem([]);
    } else {
      toast.error("No keyword selected to remove");
    }
  };

  // handle Item Click  (Tags/Modal/Post/Category/User/Badge/keyword)

  const handleTagItemClick = (item) => {
    setTagSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((tag) => tag !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleModuleItemClick = (item) => {
    setModuleSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((post) => post !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handlePostItemClick = (item) => {
    setPostSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((post) => post !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleCategoryItemClick = (item) => {
    setCategorySelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((category) => category !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleKeywordItemClick = (item) => {
    setKeywordSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((keyword) => keyword !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  // handel Remove item data click  (Tags/Modal/Post/Category/User/keyword)
  const handleRemoveTagItemClick = (item) => {
    setRemoveTagSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((tag) => tag !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemoveModuleItemClick = (item) => {
    setRemoveModuleSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((tag) => tag !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemovePostItemClick = (item) => {
    setRemovePostSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((post) => post !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemoveCategoryItemClick = (item) => {
    setRemoveCategorySelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((category) => category !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleRemoveKeywordItemClick = (item) => {
    setKeywordRemoveSelectedItem((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((keyword) => keyword !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  // handleTextChange
  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked === true ? 1 : 0) : value,
      },
    }));
  };

  // uplaod Image
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setSrc(reader.result);
        setIsMediaRemoveAndDownload(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownloadMediaClick = async (mediaFile) => {
    try {
      setMediaImageLoadingData(true);
      const urlSegments = mediaFile.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];
      await downloadBlobData(academics, lastSegment);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setMediaImageLoadingData(false);
    }
  };

  // handle media remove
  const handleRemoveMediaClick = () => {
    setSrc("");
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        channel_image: "",
      },
    }));
  };

  //handle form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.inputData.channel_title) {
      return toast.warning("All fields are required");
    }
    try {
      setLoadingData(true);
      var payload = {
        modelName: "academic_channels",
        relation: [
          {
            modelName: "academic_channel_group_linkings",
          },
          {
            modelName: "academic_channel_audience_linkings",
          },
          {
            modelName: "academic_channel_tags",
          },
          {
            modelName: "academic_channel_categories",
          },
          {
            modelName: "academic_channel_post_associations",
          },
          {
            modelName: "academic_channel_users",
          },
        ],
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      // Create a map for quick lookup of post IDs from postSelected
      const postSelectedMap = new Map();
      postSelected.forEach((post, index) => {
        postSelectedMap.set(post.id, index + 1); // Storing sequence as index + 1
      });

      // Update academic_channel_post_associations with sequence
      if (payload.inputData.academic_channel_post_associations) {
        payload.inputData.academic_channel_post_associations.forEach((post) => {
          if (post.is_active === 0) {
            post.sequence = null; // Set sequence to null if is_active is 0
          } else {
            const sequence = postSelectedMap.get(post.post_id);
            post.sequence = sequence; // Assign sequence based on postSelected if available
          }
        });
      }

      if (id) {
        payload.id = id;
      }

      const responseData = id
        ? await fetchData("updaterelationaldata", payload, academics)
        : await fetchData("createAndUpdateMaster", payload, academics);

      if (responseData.code == 1) {
        toast.success("Manage Content Channel saved successfully!");
        navigate("/content-channel-list");
      } else {
        toast.error("Error while saving Manage Content Channel!");
        navigate("/content-channel-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE CONTENT CHANNEL"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      className="needs-validation"
                      onSubmit={handleSubmit}>
                      <>
                        {" "}
                        <Row className="mt-1">
                          <Row>
                            <Row className="mt-5">
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Tenant</Label>
                                  <Select
                                    value={selectedTenant}
                                    onChange={handleSelectTenantChange}
                                    options={tenantlist}
                                    classNamePrefix="select2-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                    isClearable
                                  />
                                  {tenantError && (
                                    <span>
                                      <p
                                        style={{
                                          fontSize: "11.5px",
                                          color: "#ff4d4d",
                                        }}>
                                        Select Tenant
                                      </p>
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-1">
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Select Group
                                  </Label>
                                  <Select
                                    isMulti={true}
                                    value={selectedGroup}
                                    onChange={handleSelectGroupChange}
                                    options={selectedGroupOption}
                                    classNamePrefix="select1-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                    styles={dropStyle}
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Select Audiance
                                  </Label>
                                  <Select
                                    isMulti={true}
                                    value={selectedAudiance}
                                    onChange={handleSelectAudienceChange}
                                    options={selectedAudianceOption}
                                    classNamePrefix="select2-selection"
                                    noOptionsMessage={() => "no data found.."}
                                    maxMenuHeight={200}
                                    styles={dropStyle}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-1">
                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Channel Title
                                    <Asterisk />
                                  </Label>
                                  <AvField
                                    name="channel_title"
                                    placeholder="Channel Title"
                                    type="text"
                                    errorMessage="Enter Channel Title Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={formData.inputData.channel_title}
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Speaker Group Name
                                  </Label>
                                  <AvField
                                    name="speaker_name"
                                    placeholder="Speaker Group Name"
                                    type="text"
                                    errorMessage="Enter Speaker Group Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    value={formData.inputData.speaker_name}
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Released Date
                                  </Label>
                                  <AvField
                                    name="released_date"
                                    placeholder="Released Date"
                                    type="date"
                                    errorMessage="Enter Released Date Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    value={formData.inputData.released_date}
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Speaker Stock id
                                  </Label>
                                  <AvField
                                    name="speaker_stock_id"
                                    placeholder="Speaker Stock id"
                                    type="text"
                                    errorMessage="Enter Speaker Stock id Name"
                                    className="form-control"
                                    onChange={handleTextChange}
                                    value={formData.inputData.speaker_stock_id}
                                  />
                                </div>
                              </Col>

                              <Col md={6}>
                                <Label
                                  className="form-label"
                                  htmlFor="validationCustom01">
                                  Image
                                </Label>
                                <div className="input-group">
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="customFile"
                                    onChange={handleFileChange}
                                  />
                                </div>
                                {src && (
                                  <Col md="6" className="mt-1">
                                    <div className="mb-3 d-flex">
                                      <div>
                                        <img
                                          src={src}
                                          alt="channel image"
                                          width="120px"
                                          height="100px"
                                          style={{
                                            border: ".5px solid gray",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      </div>
                                      {!isMediaRemoveAndDownload && (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginLeft: "10px",
                                          }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "15px",
                                            }}>
                                            {loadingMediaImageData ? (
                                              <LoaderDownloadMedia />
                                            ) : (
                                              <img
                                                src={downloadIcon}
                                                alt="download"
                                                width="30px"
                                                height="30px"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleDownloadMediaClick(src)
                                                }
                                              />
                                            )}
                                            <img
                                              src={removeIcon}
                                              alt="remove"
                                              width="30px"
                                              height="30px"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={handleRemoveMediaClick}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                )}
                              </Col>
                              <Col md="6">
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="validationCustom01">
                                    Summary
                                  </Label>
                                  <AvField
                                    name="summary"
                                    placeholder="Summary "
                                    type="textarea"
                                    errorMessage="Enter Summary"
                                    rows="3"
                                    onChange={handleTextChange}
                                    value={formData.inputData.summary}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Row>

                          <Row className="mt-3">
                            <Col
                              style={{
                                textAlign: "center",
                              }}>
                              <div className="form-check mt-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="isHavingModule"
                                  required=""
                                  name="is_having_module"
                                  value={formData.inputData.is_having_module}
                                  checked={formData.inputData.is_having_module}
                                  onChange={handleTextChange}
                                  style={{ width: 18, height: 18 }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="enable_notification"
                                  style={{ fontSize: 16, marginLeft: 10 }}>
                                  Is Having Module
                                </label>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-5">
                            <div className="m-3">
                              <h5
                                id="tagSection"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  fontSize: 18,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: !tagsIsHide ? "black" : "gray",
                                }}
                                onClick={handelTags}>
                                Tags
                                <i
                                  className={`mdi mdi-chevron-${
                                    tagsIsHide ? "down" : "up"
                                  } mt-1`}
                                  style={{ fontSize: "22px" }}
                                />
                              </h5>
                            </div>
                            {!tagsIsHide && (
                              <Row
                                // id="tagSection"
                                style={{
                                  marginLeft: "1px",
                                  padding: "10px",
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 2px 1px gray",
                                }}>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    marginTop: "17px",
                                  }}></Row>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                  }}>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label "
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selection: {tagFilteredSelection.length}
                                      </Label>
                                      <Col>
                                        <AvField
                                          name="tagsearch"
                                          placeholder="Search Selection Tag"
                                          type="search"
                                          // errorMessage="Enter Type of Search"
                                          className="form-control m-0"
                                          id="validationCustom01"
                                          onChange={handleTagSelectionSearch}
                                          value={tagSelectionSearch}
                                        />
                                      </Col>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {tagFilteredSelection.map(
                                          (item, index) => {
                                            const isTagAlreadySelected =
                                              tagSelected.some(
                                                (tag) => tag.id === item.id
                                              );

                                            const isItemSelected =
                                              tagSelectedItem.some(
                                                (tag) => tag.id === item.id
                                              );

                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  cursor: isTagAlreadySelected
                                                    ? "not-allowed"
                                                    : "pointer",
                                                  padding: "5px",
                                                  backgroundColor:
                                                    isItemSelected
                                                      ? "lightblue"
                                                      : "",
                                                  color: isTagAlreadySelected
                                                    ? "gray"
                                                    : "black",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  !isTagAlreadySelected &&
                                                  handleTagItemClick(item)
                                                }>
                                                {item.tag_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col
                                    md="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-right"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleTagSelect}
                                        />
                                      </div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-left"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleTagRemove}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selected: {tagFilteredSelected.length}
                                      </Label>
                                      <Col>
                                        <AvField
                                          name="tagsearch"
                                          placeholder="Search Selected Tag"
                                          type="search"
                                          // errorMessage="Enter Type of Search"
                                          className="form-control m-0"
                                          id="validationCustom01"
                                          onChange={handleTagSelectedSearch}
                                          value={tagSelectedSearch}
                                        />
                                      </Col>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {tagFilteredSelected.map(
                                          (item, index) => {
                                            const isItemSelectedForRemoval =
                                              tagRemoveSelectedItem.some(
                                                (tag) => tag.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  padding: "5px",
                                                  cursor: "pointer",
                                                  backgroundColor:
                                                    isItemSelectedForRemoval
                                                      ? "lightblue"
                                                      : "",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  handleRemoveTagItemClick(item)
                                                }>
                                                {item.tag_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>
                            )}
                          </Row>
                          {formData.inputData.is_having_module ? (
                            <Row className="mt-5">
                              <div className="m-3">
                                <h5
                                  id="moduleSection"
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    fontSize: 18,
                                    color: !moduleIsHide ? "black" : "gray",
                                  }}
                                  onClick={handelModule}>
                                  Module{" "}
                                  <i
                                    className={`mdi mdi-chevron-${
                                      moduleIsHide ? "down" : "up"
                                    } mt-1`}
                                    style={{ fontSize: "22px" }}
                                  />
                                </h5>
                              </div>
                              {!moduleIsHide && (
                                <Row
                                  style={{
                                    marginLeft: "1px",
                                    padding: "10px",
                                    border: "1px solid gray",
                                    borderRadius: "5px",
                                    boxShadow: "0px 0px 2px 1px gray",
                                  }}>
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: 0,
                                      padding: 0,
                                      marginTop: "17px",
                                    }}></Row>
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: 0,
                                      padding: 0,
                                    }}>
                                    <Col md="4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label "
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          Selection:{" "}
                                          {moduleFilteredSelection.length}
                                        </Label>
                                        <Col>
                                          <AvField
                                            name="modulesearch"
                                            placeholder="Search Selection Module"
                                            type="search"
                                            // errorMessage="Enter Type of Search"
                                            className="form-control m-0"
                                            id="validationCustom02"
                                            onChange={
                                              handleModuleSelectionSearch
                                            }
                                            value={moduleSelectionSearch}
                                          />
                                        </Col>
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: 0,
                                            border: "1px solid black",
                                            height: "200px",
                                            overflow: "auto",
                                          }}>
                                          {moduleFilteredSelection.map(
                                            (item, index) => {
                                              const isModuleAlreadySelected =
                                                moduleSelected.some(
                                                  (module) =>
                                                    module.id === item.id
                                                );
                                              const isItemSelected =
                                                moduleSelectedItem.some(
                                                  (module) =>
                                                    module.id === item.id
                                                );
                                              return (
                                                <li
                                                  key={index}
                                                  style={{
                                                    cursor:
                                                      isModuleAlreadySelected
                                                        ? "not-allowed"
                                                        : "pointer",
                                                    padding: "5px",
                                                    backgroundColor:
                                                      isItemSelected
                                                        ? "lightblue"
                                                        : "",
                                                    color:
                                                      isModuleAlreadySelected
                                                        ? "gray"
                                                        : "black",
                                                    marginBlock: "2px",
                                                  }}
                                                  onClick={() =>
                                                    !isModuleAlreadySelected &&
                                                    handleModuleItemClick(item)
                                                  }>
                                                  {item.title}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </Col>
                                    <Col
                                      md="1"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}>
                                      <div>
                                        <div>
                                          <i
                                            className="fas fa-long-arrow-alt-right"
                                            style={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handleModuleSelect}
                                          />
                                        </div>
                                        <div>
                                          <i
                                            className="fas fa-long-arrow-alt-left"
                                            style={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handleModuleRemove}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          Selected:{" "}
                                          {moduleFilteredSelected.length}
                                        </Label>
                                        <Col>
                                          <AvField
                                            name="modulesearch"
                                            placeholder="Search Selected Module"
                                            type="search"
                                            // errorMessage="Enter Type of Search"
                                            className="form-control m-0"
                                            id="validationCustom02"
                                            onChange={
                                              handleModuleSelectedSearch
                                            }
                                            value={moduleSelectedSearch}
                                          />
                                        </Col>
                                        <ul
                                          // id="postSection"
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: 0,
                                            border: "1px solid black",
                                            height: "200px",
                                            overflow: "auto",
                                          }}>
                                          {moduleFilteredSelected.map(
                                            (item, index) => {
                                              const isItemSelectedForRemoval =
                                                moduleRemoveSelectedItem.some(
                                                  (module) =>
                                                    module.id === item.id
                                                );
                                              return (
                                                <li
                                                  key={index}
                                                  style={{
                                                    padding: "5px",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                      isItemSelectedForRemoval
                                                        ? "lightblue"
                                                        : "",
                                                    marginBlock: "2px",
                                                  }}
                                                  onClick={() =>
                                                    handleRemoveModuleItemClick(
                                                      item
                                                    )
                                                  }>
                                                  {item.title}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              )}
                            </Row>
                          ) : (
                            <Row className="mt-5">
                              <div className="m-3">
                                <h5
                                  id="postSection"
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    fontSize: 18,
                                    color: !postIsHide ? "black" : "gray",
                                  }}
                                  onClick={handelPost}>
                                  Posts{" "}
                                  <i
                                    className={`mdi mdi-chevron-${
                                      postIsHide ? "down" : "up"
                                    } mt-1`}
                                    style={{ fontSize: "22px" }}
                                  />
                                </h5>
                              </div>
                              {!postIsHide && (
                                <Row
                                  // id="postSection"
                                  style={{
                                    marginLeft: "1px",
                                    padding: "10px",
                                    border: "1px solid gray",
                                    borderRadius: "5px",
                                    boxShadow: "0px 0px 2px 1px gray",
                                  }}>
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: 0,
                                      padding: 0,
                                      marginTop: "17px",
                                    }}></Row>
                                  <Row
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: 0,
                                      padding: 0,
                                    }}>
                                    <Col md="4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label "
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          Selection:{" "}
                                          {postFilteredSelection.length}
                                        </Label>
                                        <Col>
                                          <AvField
                                            name="postsearch"
                                            placeholder="Search Selection Post"
                                            type="search"
                                            // errorMessage="Enter Type of Search"
                                            className="form-control m-0"
                                            id="validationCustom03"
                                            onChange={
                                              handlePostSelectiontSearch
                                            }
                                            value={postSelectionSearch}
                                          />
                                        </Col>
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: 0,
                                            border: "1px solid black",
                                            height: "200px",
                                            overflow: "auto",
                                          }}>
                                          {postFilteredSelection.map(
                                            (item, index) => {
                                              const isPostAlreadySelected =
                                                postSelected.some(
                                                  (post) => post.id === item.id
                                                );
                                              const isItemSelected =
                                                postSelectedItem.some(
                                                  (post) => post.id === item.id
                                                );
                                              return (
                                                <li
                                                  key={index}
                                                  style={{
                                                    cursor:
                                                      isPostAlreadySelected
                                                        ? "not-allowed"
                                                        : "pointer",
                                                    padding: "5px",
                                                    backgroundColor:
                                                      isItemSelected
                                                        ? "lightblue"
                                                        : "",
                                                    color: isPostAlreadySelected
                                                      ? "gray"
                                                      : "black",
                                                    marginBlock: "2px",
                                                  }}
                                                  onClick={() =>
                                                    !isPostAlreadySelected &&
                                                    handlePostItemClick(item)
                                                  }>
                                                  {item.title}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </Col>
                                    <Col
                                      md="1"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}>
                                      <div>
                                        <div>
                                          <i
                                            className="fas fa-long-arrow-alt-right"
                                            style={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handlePostSelect}
                                          />
                                        </div>
                                        <div>
                                          <i
                                            className="fas fa-long-arrow-alt-left"
                                            style={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                            onClick={handlePostRemove}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}>
                                          Selected:{" "}
                                          {postFilteredSelected.length}
                                        </Label>
                                        <Col>
                                          <AvField
                                            name="postsearch"
                                            placeholder="Search Selected Post"
                                            type="search"
                                            // errorMessage="Enter Type of Search"
                                            className="form-control m-0"
                                            id="validationCustom03"
                                            onChange={handlePostSeletedSearch}
                                            value={postSelectedSearch}
                                          />
                                        </Col>
                                        <ul
                                          // id="postSection"
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: 0,
                                            border: "1px solid black",
                                            height: "200px",
                                            overflow: "auto",
                                          }}>
                                          {postFilteredSelected
                                            .sort(
                                              (a, b) => a.sequence - b.sequence
                                            )
                                            .map((item, index) => {
                                              const isItemSelectedForRemoval =
                                                postRemoveSelectedItem.some(
                                                  (post) => post.id === item.id
                                                );
                                              return (
                                                <li
                                                  key={index}
                                                  style={{
                                                    padding: "5px",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                      isItemSelectedForRemoval
                                                        ? "lightblue"
                                                        : "",
                                                    marginBlock: "2px",
                                                  }}
                                                  onClick={() =>
                                                    handleRemovePostItemClick(
                                                      item
                                                    )
                                                  }>
                                                  {item.title}
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              )}
                            </Row>
                          )}

                          <Row className="mt-5">
                            <div className="m-3">
                              <h5
                                id="categoriesSection"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  fontSize: 18,
                                  color: !categoriesIsHide ? "black" : "gray",
                                }}
                                onClick={handelCategories}>
                                Categories{" "}
                                <i
                                  className={`mdi mdi-chevron-${
                                    categoriesIsHide ? "down" : "up"
                                  } mt-1`}
                                  style={{ fontSize: "22px" }}
                                />
                              </h5>
                            </div>
                            {!categoriesIsHide && (
                              <Row
                                // id="categoriesSection"
                                style={{
                                  marginLeft: "1px",
                                  padding: "10px",
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 2px 1px gray",
                                }}>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    marginTop: "17px",
                                  }}></Row>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                  }}>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label "
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selection:{" "}
                                        {categoryFilteredSelection.length}
                                      </Label>
                                      <Col>
                                        <AvField
                                          name="categorysearch"
                                          placeholder="Search Selection Category"
                                          type="search"
                                          // errorMessage="Enter Type of Search"
                                          className="form-control m-0"
                                          id="validationCustom04"
                                          onChange={
                                            handleCategorySelectionSearch
                                          }
                                          value={categorySelectionSearch}
                                        />
                                      </Col>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {categoryFilteredSelection.map(
                                          (item, index) => {
                                            const isCategoryAlreadySelected =
                                              categorySelected.some(
                                                (category) =>
                                                  category.id === item.id
                                              );
                                            const isItemSelected =
                                              categorySelectedItem.some(
                                                (category) =>
                                                  category.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  cursor:
                                                    isCategoryAlreadySelected
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  padding: "5px",
                                                  backgroundColor:
                                                    isItemSelected
                                                      ? "lightblue"
                                                      : "",
                                                  color:
                                                    isCategoryAlreadySelected
                                                      ? "gray"
                                                      : "black",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  !isCategoryAlreadySelected &&
                                                  handleCategoryItemClick(item)
                                                }>
                                                {item.category_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col
                                    md="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-right"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleCategorySelect}
                                        />
                                      </div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-left"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleCategoryRemove}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selected:{" "}
                                        {categoryFilteredSelected.length}
                                      </Label>
                                      <Col>
                                        <AvField
                                          name="categorysearch"
                                          placeholder="Search Selected Category"
                                          type="search"
                                          // errorMessage="Enter Type of Search"
                                          className="form-control m-0"
                                          id="validationCustom04"
                                          onChange={
                                            handleCategorySelectedSearch
                                          }
                                          value={categorySelectedSearch}
                                        />
                                      </Col>
                                      <ul
                                        // id="categoriesSection"
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {categoryFilteredSelected.map(
                                          (item, index) => {
                                            const isItemSelectedForRemoval =
                                              categoryRemoveSelectedItem.some(
                                                (category) =>
                                                  category.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  padding: "5px",
                                                  cursor: "pointer",
                                                  backgroundColor:
                                                    isItemSelectedForRemoval
                                                      ? "lightblue"
                                                      : "",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  handleRemoveCategoryItemClick(
                                                    item
                                                  )
                                                }>
                                                {item.category_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>
                            )}
                          </Row>

                          <Row className="mt-5">
                            <div className="m-3">
                              <h5
                                id="keywordSection"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  fontSize: 18,
                                  color: !keywordIsHide ? "black" : "gray",
                                }}
                                onClick={handelKeyword}>
                                Keywords{" "}
                                <i
                                  className={`mdi mdi-chevron-${
                                    keywordIsHide ? "down" : "up"
                                  } mt-1`}
                                  style={{ fontSize: "22px" }}
                                />
                              </h5>
                            </div>
                            {!keywordIsHide && (
                              <Row
                                style={{
                                  marginLeft: "1px",
                                  padding: "10px",
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                  boxShadow: "0px 0px 2px 1px gray",
                                }}>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                    marginTop: "17px",
                                  }}></Row>
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 0,
                                    padding: 0,
                                  }}>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label "
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selection:{" "}
                                        {keywordFilteredSelection.length}
                                      </Label>
                                      <Col>
                                        <AvField
                                          name="keywordsearch"
                                          placeholder="Search Selection Keyword"
                                          type="search"
                                          className="form-control m-0"
                                          id="validationCustom05"
                                          onChange={
                                            handleKeywordSelectionSearch
                                          }
                                          value={keywordSelectionSearch}
                                        />
                                      </Col>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {keywordFilteredSelection.map(
                                          (item, index) => {
                                            const isKeywordAlreadySelected =
                                              keywordSelected.some(
                                                (keyword) =>
                                                  keyword.id === item.id
                                              );
                                            const isItemSelected =
                                              KeywordSelectedItem.some(
                                                (keyword) =>
                                                  keyword.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  cursor:
                                                    isKeywordAlreadySelected
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  padding: "5px",
                                                  backgroundColor:
                                                    isItemSelected
                                                      ? "lightblue"
                                                      : "",
                                                  color:
                                                    isKeywordAlreadySelected
                                                      ? "gray"
                                                      : "black",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  !isKeywordAlreadySelected &&
                                                  handleKeywordItemClick(item)
                                                }>
                                                {item.keyword_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                  <Col
                                    md="1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-right"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleKeywordSelect}
                                        />
                                      </div>
                                      <div>
                                        <i
                                          className="fas fa-long-arrow-alt-left"
                                          style={{
                                            fontSize: "22px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleKeywordRemove}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}>
                                        Selected:{" "}
                                        {keywordFilteredSelected.length}
                                      </Label>
                                      <Col>
                                        <AvField
                                          name="keywordsearch"
                                          placeholder="Search Selection Keyword"
                                          type="search"
                                          className="form-control m-0"
                                          id="validationCustom05"
                                          onChange={handleKeywordSelectedSearch}
                                          value={keywordSelectedSearch}
                                        />
                                      </Col>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          paddingLeft: 0,
                                          border: "1px solid black",
                                          height: "200px",
                                          overflow: "auto",
                                        }}>
                                        {keywordFilteredSelected.map(
                                          (item, index) => {
                                            const isItemSelectedForRemoval =
                                              KeywordRemoveSelectedItem.some(
                                                (keyword) =>
                                                  keyword.id === item.id
                                              );
                                            return (
                                              <li
                                                key={index}
                                                style={{
                                                  padding: "5px",
                                                  cursor: "pointer",
                                                  backgroundColor:
                                                    isItemSelectedForRemoval
                                                      ? "lightblue"
                                                      : "",
                                                  marginBlock: "2px",
                                                }}
                                                onClick={() =>
                                                  handleRemoveKeywordItemClick(
                                                    item
                                                  )
                                                }>
                                                {item.keyword_name}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>
                            )}
                          </Row>
                        </Row>
                        <Row className="mt-4">
                          <Col lg="4">
                            <div
                              style={{ display: "inline-flex", gap: "14px" }}>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.inputData.shareable}
                                  id="invalid_Check"
                                  name="shareable"
                                  // disabled={!!selectedTenant}
                                  checked={formData.inputData.shareable}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalid_Check">
                                  Is Shareable
                                </label>
                              </div>

                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={formData.inputData.is_active}
                                  id="invalidCheck"
                                  name="is_active"
                                  checked={formData.inputData.is_active}
                                  onChange={handleTextChange}
                                  required=""
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="invalidCheck">
                                  Is Active
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={() => navigate("/content-channel-list")}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContentChannelCreate;

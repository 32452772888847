import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  dropdownData,
  fetchData,
  academics,
  social,
} from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Asterisk from "../../Asterisk/Asterisk";
import { dropStyle } from "../../../Style/dropStyle";
import { LoaderCreatePage } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";

const GroupBroadCast = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [breadcrumbItems] = useState([]);

  //selected group drop
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedGroupOption, setSelectedGroupOption] = useState([]);
  const [groupError, setGroupError] = useState(false);

  //loading
  const [loadingData, setLoadingData] = useState(id ? true : false);

  const [formData, setFormData] = useState({
    tenant_id: null,
    group_id: [],
    title: "",
    body: "",
    sender_user_id: JSON.parse(localStorage.getItem("logedin-user"))[0].id,
  });

  useEffect(() => {
    getSelectGroupIdDropData();
  }, []);

  const getSelectGroupIdDropData = async () => {
    try {
      let payload = {
        modelName: "group_id_masters",
        whereCondition: {
          is_active: 1,
          is_deleted: 0,
        },
      };
      const responseData = await fetchData("getMasterList", payload);

      if (responseData.code == 1) {
        const dropdownResponseData = await dropdownData(
          responseData.data,
          "llc_group_id"
        );

        const flattenedOptions = dropdownResponseData.reduce((acc, item) => {
          return acc.concat(item.options);
        }, []);

        setSelectedGroupOption([
          { value: "select-all", label: "Select All" },
          ...flattenedOptions,
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleSelectGroupChange = async (selectedOption) => {
    setGroupError(!selectedOption);
    let mapsSlectedOptionValues = selectedOption.map((item) =>item.label.toString()
    );
    if (
      selectedOption &&
      selectedOption.some((option) => option.value === "select-all")
    ) {
      setSelectedGroup(
        selectedGroupOption.filter((option) => option.value !== "select-all")
      );
      const selectedValues = selectedGroupOption.filter((option) => option.value !== "select-all").map((option) => option.label.toString());
      setFormData((prevState) => ({
        ...prevState,
        group_id: selectedValues,
      }));
    } else {
      setSelectedGroup(selectedOption);
      setFormData((prevState) => ({
        ...prevState,
        group_id: mapsSlectedOptionValues,
      }));
    }
  };



  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    !selectedGroup.length > 0 ? setGroupError(true) : setGroupError(false);

    if (!selectedGroup.length > 0 || !formData.title || !formData.body) {
      return toast.warning("All fields are required");
    }
    try {
      setLoadingData(true);
      var payload = formData;

      const responseData = await fetchData(
        "groupwiseusernotification",
        payload,
        social
      );
      if (responseData.code == 1) {
        toast.success(responseData.message);
        navigate("/group-broadcast");
        // window.location.reload();
      } else {
        toast.error(responseData.message);
        navigate("/group-broadcast");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE GROUP BROADCAST"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        {" "}
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Select Group Id
                                <Asterisk />
                              </Label>
                              <Select
                                isMulti={true}
                                value={selectedGroup}
                                onChange={handleSelectGroupChange}
                                options={selectedGroupOption}
                                classNamePrefix="select1-selection"
                                noOptionsMessage={() => "no data found.."}
                                maxMenuHeight={200}
                                styles={dropStyle}
                              />
                              {groupError && (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "11.5px",
                                      color: "red",
                                    }}>
                                    Select Group id
                                  </p>
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Title
                                <Asterisk />
                              </Label>
                              <AvField
                                name="title"
                                placeholder="Title"
                                type="text"
                                errorMessage="Enter Title"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.title}
                              />
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Body
                                <Asterisk />
                              </Label>
                              <AvField
                                name="body"
                                placeholder="Body"
                                type="textarea"
                                row="3"
                                errorMessage="Enter Body"
                                className="form-control"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.body}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          Send
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={() => navigate("/group-broadcast")}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GroupBroadCast;

import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { fetchData, image_url, academics, downloadBlobData } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Asterisk from "../../Asterisk/Asterisk";
import { LoaderCreatePage, LoaderDownloadMedia } from "../../Loader/Loader";
import {
  formContentContainer,
  loaderOverlay,
} from "../../../Style/loaderOverly";
import downloadIcon from "../../../assets/images/download.png";
import removeIcon from "../../../assets/images/remove.png";

const AcademicBannerCreate = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [breadcrumbItems] = useState([]);
  const [loadingData, setLoadingData] = useState(id ? true : false);
  const [isMediaRemoveAndDownload, setIsMediaRemoveAndDownload] =
    useState(false);
  const [loadingMediaImageData, setMediaImageLoadingData] = useState(false);

  const [formData, setFormData] = useState({
    inputData: {
      type: 1,
      banner_image: "",
      redirection_url: "",
      sequence: "",
      is_deleted: 0,
      is_active: 1,
    },
    uploadImage: {
      imagePath: "",
      folderPath: "academics",
      field_name: "banner_image",
    },
  });
  const [fetchedData, setFetchedData] = useState(null);
  const [src, setSrc] = useState("");

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (fetchedData) {
      let data = fetchedData;
      setFormData((prevState) => ({
        ...prevState,
        inputData: {
          ...prevState.inputData,
          ...data,
        },
      }));

      if (data.banner_image) {
        setSrc(image_url + data.banner_image);
      }
    }
  }, [fetchedData]);

  const getData = async (id) => {
    try {
      let payload = {
        modelName: "academy_content_banner_images",
        whereCondition: {
          type: 1,
          is_active: 1,
        },
      };
      if (id) {
        payload.whereCondition = { id: id, ...payload.whereCondition };
      }
      const responseData = await fetchData("getMasterList", payload, academics);
      if (responseData.data && responseData.data.length > 0) {
        let data = responseData.data[0];
        if (id) {
          setFetchedData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleTextChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        [name]: type === "checkbox" ? (checked == true ? 1 : 0) : value,
      },
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          uploadImage: {
            ...prevState.uploadImage,
            imagePath: reader.result,
          },
        }));
        setSrc(reader.result);
        setIsMediaRemoveAndDownload(true);
      };
      reader.readAsDataURL(file);
    }
  };

  // handle media download
  const handleDownloadMediaClick = async (mediaFile) => {
    try {
      setMediaImageLoadingData(true);
      const urlSegments = mediaFile.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];
      await downloadBlobData(academics, lastSegment);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setMediaImageLoadingData(false);
    }
  };

  // handle media remove
  const handleRemoveMediaClick = () => {
    setSrc("");
    setFormData((prevState) => ({
      ...prevState,
      inputData: {
        ...prevState.inputData,
        banner_image: "",
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.inputData.sequence) {
      return toast.warning("Sequence is required!");
    }

    if (!src) {
      return toast.warning("Banner Image is required!");
    }

    try {
      setLoadingData(true);
      var payload = {
        modelName: "academy_content_banner_images",
        inputData: formData.inputData,
      };

      if (formData.uploadImage?.imagePath) {
        payload.uploadImage = formData.uploadImage;
      }

      if (id) {
        payload.id = id;
      }

      console.log("submetting academic banner data =>", payload);
      const responseData = await fetchData(
        "createAndUpdateMaster",
        payload,
        academics
      );
      if (responseData.code == 1) {
        toast.success("Academic Banner saved successfully!");
        navigate("/academic-banner-list");
      } else {
        toast.error("Error While saving Academic Banner!");
        navigate("/academic-banner-list");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleBackClick = () => {
    navigate("/academic-banner-list");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="CREATE ACADEMIC BANNER"
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div style={formContentContainer}>
                    <AvForm
                      onSubmit={handleSubmit}
                      className="needs-validation">
                      <>
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Redirection url
                              </Label>
                              <AvField
                                name="redirection_url"
                                placeholder="Redirection url"
                                type="url"
                                errorMessage="Enter redirection url"
                                className="form-control"
                                onChange={handleTextChange}
                                value={formData.inputData.redirection_url}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                Sequence <Asterisk />
                              </Label>
                              <AvField
                                name="sequence"
                                placeholder="Sequence"
                                type="number"
                                errorMessage="Enter sequence"
                                className="form-control"
                                min="1"
                                onChange={handleTextChange}
                                validate={{
                                  required: { value: true },
                                }}
                                value={formData.inputData.sequence}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <Label
                              className="form-label"
                              htmlFor="validationCustom01">
                              Banner Image
                              <Asterisk />
                            </Label>
                            <div className="input-group">
                              <input
                                name="imagePath"
                                type="file"
                                className="form-control"
                                id="customFile"
                                onChange={handleFileChange}
                              />
                            </div>
                            {src && (
                              <Col md="6" className="mt-1">
                                <div className="mb-3 d-flex">
                                  <div>
                                    <img
                                      src={src}
                                      alt="picture"
                                      width="120px"
                                      height="100px"
                                      style={{
                                        border: ".5px solid gray",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </div>
                                  {!isMediaRemoveAndDownload && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "10px",
                                      }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "15px",
                                        }}>
                                        {loadingMediaImageData ? (
                                          <LoaderDownloadMedia />
                                        ) : (
                                          <img
                                            src={downloadIcon}
                                            alt="download"
                                            width="30px"
                                            height="30px"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDownloadMediaClick(src)
                                            }
                                          />
                                        )}
                                        <img
                                          src={removeIcon}
                                          alt="remove"
                                          width="30px"
                                          height="30px"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={handleRemoveMediaClick}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            )}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg="12">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={formData.inputData.is_active}
                                id="invalidCheck"
                                name="is_active"
                                checked={formData.inputData.is_active}
                                onChange={handleTextChange}
                                required=""
                              />
                              <label
                                className="form-check-label"
                                htmlFor="invalidCheck">
                                Is Active
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="primary"
                          type="submit"
                          className=" waves-effect waves-light me-1">
                          {id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="primary"
                          className=" waves-effect waves-light me-1"
                          onClick={handleBackClick}>
                          Back
                        </Button>
                      </>
                    </AvForm>
                    {loadingData && (
                      <div style={loaderOverlay}>
                        <LoaderCreatePage />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AcademicBannerCreate;

import React, { useEffect, useMemo, useState, useRef } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { fetchData, academics } from "../../../services/fetchData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationToast } from "../../../services/toaster/ConfirmToaster";

const breadcrumbItems = [];

const AcademicPostList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [channelTypeId, setChannelTypeId] = useState([]);
  const [moduleTypeId, setModuleTypeId] = useState([]);
  const isToastOpenRef = useRef(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getData();
  }, [searchKeyword, channelTypeId, moduleTypeId, page, pageSize]);
  const nextToPages = () => {
    setPage(page + 1);
  };

  const previousToPages = () => {
    setPage(page - 1);
  };

  const handlePageSizeChange = (data) => {
    setPageSize(data);
     const newTotalPages = Math.ceil(totalCount / data);
     if (page >= newTotalPages) {
       setPage(0);
     }
  };

  const lastToPages = () => {
    setPage(Math.ceil(totalCount / pageSize) - 1);
  };

  const previousFirstToPages = () => {
    setPage(0);
  };

  const getData = async () => {
    try {
      let payload = "";
      let apiUrl = "";
      if (channelTypeId.length > 0 || moduleTypeId.length > 0) {
        payload = {
          type: 1,
          channel_id: channelTypeId,
          module_id: moduleTypeId,
          search: {
            field_name: ["title", "subtitle"],
            searchKeyword: searchKeyword,
          },
        };
        apiUrl = "postlistbytype";
      } else {
        // payload
        payload = {
          modelName: "academic_channel_posts",
          whereCondition: {
            is_deleted: 0,
            type: 1,
          },
          search: {
            field_name: ["title", "subtitle"],
            searchKeyword: searchKeyword,
          },
          pagination: {
            page: page + 1,
            pageSize: pageSize,
          },
          orderBy: {
            fieldName: "id",
            orderBy: "ASC",
          },
        };
        apiUrl = "getMasterList";
      }
      const responseData = await fetchData(apiUrl, payload, academics);

      // Assign an `index` property starting from 1
      responseData.data.forEach((element, index) => {
        element.index = index + 1;
      });
      setTotalCount(responseData.count);
      // responseData.data.forEach((element, index) => {
      //   element.index = page * pageSize + (index + 1);
      // });

      const sortedData = responseData.data.sort((a, b) => b.id - a.id);

      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate("/academic-post-create");
  };

  const handleEditClick = (id) => {
    navigate(`/academic-post-create/${id}`);
  };

  const getSearchInputData = (inputValue) => {
    setSearchKeyword(inputValue);
  };

  const handleDeleteClick = async (id) => {
    if (isToastOpenRef.current) return;
    isToastOpenRef.current = true;
    const onConfirm = async () => {
      const payload = {
        type: "Post",
        id: id,
        confirmDelete: true,
      };
      const responseData = await fetchData(
        "academicContentDelete",
        payload,
        academics
      );
      if (responseData && responseData.code === 1) {
        toast.success(responseData.message);
        getData();
      } else {
        toast.error("Error while deleting the channel.");
      }
      isToastOpenRef.current = false;
    };

    const onCancel = () => {
      toast.info("Deletion cancelled");
      isToastOpenRef.current = false;
    };

    const onClose = () => {
      isToastOpenRef.current = false;
    };

    showConfirmationToast(
      "Are you sure you want to delete this Post?",
      onConfirm,
      onCancel,
      onClose
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        // accessor: "index",
        accessor: (row) => row.id && row.id,
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },

      {
        Header: "Post Title",
        accessor: (row) => (row.title && row.title ? row.title : "-"),
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "250px" }}>{value}</div>,
      },
      {
        Header: "Type",
        accessor: (row) => (row.media && row.media.type ? row.media.type : "-"),
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "100px" }}>{value}</div>,
      },
      {
        Header: "Speaker Name",
        accessor: (row) =>
          row.speaker_name && row.speaker_name ? row.speaker_name : "-",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "250px" }}>{value}</div>,
      },
      {
        Header: "Version",
        accessor: (row) => (row.version && row.version ? row.version : "-"),
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => <div style={{ width: "50px" }}>{value}</div>,
      },

      {
        Header: "Is Active",
        accessor: "is_active",
        disableFilters: true,
        filterable: false,
        Cell: ({ value }) => (
          <div style={{ width: "100px" }}>
            {value === 0 ? "In Active" : "Active"}
          </div>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }) => (
          <div style={{ width: "150px" }}>
            <Button
              color="primary"
              onClick={() => handleEditClick(row.original.id)}>
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              color="danger"
              onClick={() => handleDeleteClick(row.original.id)}
              style={{ marginLeft: "10px" }}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const getChannelTypeId = (channeId) => {
    setChannelTypeId(channeId);
  };

  const getModuleTypeId = (moduleId) => {
    setModuleTypeId(moduleId);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}>
            <Breadcrumbs
              title="Academic Post List"
              breadcrumbItems={breadcrumbItems}
            />
            <Button
              color="primary"
              className="waves-effect waves-light me-1"
              onClick={handleClickAdd}
              style={{ marginLeft: "auto" }}>
              Create Academic Post
            </Button>
          </div>

          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={data || []}
                isPagination={false}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                isLoading={isLoading}
                onSearchInputData={getSearchInputData}
                onChannelDropselect={getChannelTypeId}
                onModuleDropselect={getModuleTypeId}
                pageIndexProp={page}
                onPageSizeChange={handlePageSizeChange}
                nextToPage={nextToPages}
                lastToPage={lastToPages}
                previousToPage={previousToPages}
                previousFirstToPage={previousFirstToPages}
                totalCount={totalCount}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AcademicPostList;
